<template>
  <v-card flat class="mt-5">
    <v-form class="multi-col-validation">
      <div class="pa-3">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field v-model="userData.name" label="Nome Completo" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="userData.nationality" label="Nacionalidade" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="userData.maritalStatus"
                label="Estado Civil"
                outlined
                dense
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="userData.activity" label="Atividade" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3" v-if="userData.cpf">
              <v-text-field v-model="userData.cpf" label="CPF" outlined dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" md="3" v-if="userData.rg">
              <v-text-field v-model="userData.rg" label="RG" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3" v-if="userData.cnpj">
              <v-text-field v-model="userData.cnpj" label="CNPJ" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="9">
              <v-text-field v-model="userData.address" label="Endereço Completo" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="userData.complement" label="Complemento" outlined dense disabled></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="userData.email" type="email" label="E-mail" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="userData.phone" type="tel" label="Telefone" outlined dense></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-2"> Salvar </v-btn>
              <v-btn color="secondary" outlined class="mt-2"> Cancelar </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    userData: {}
  }),
  watch: {
    user: {
      handler() {
        this.userData = this.user;
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },

};
</script>
