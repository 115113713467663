<template>
  <v-card id="user-profile-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in loading ? [] : tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <info></info>
      </v-tab-item>

      <v-tab-item>
        <password></password>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
// demos
import info from "./Info.vue";
import password from "./Password.vue";

import { mapActions } from "vuex";

export default {
  data: () => ({
    tabs: [
      { title: "Dados Pessoais", icon: "mdi-account-outline" },
      { title: "Alterar Senha", icon: "mdi-form-textbox-password" }
    ],
    tab: 0,
    loading: false,
    user: {}
  }),
  methods: {
    ...mapActions("account", ["getMe"]),
    async getUser() {
      try {
        let user = await this.getMe();
        this.user = user;
        this.loading = false;
      } catch (error) {
        this.$toast.fire({
          icon: "error",
          title: "Erro ao carregar dados do usuário"
        });
      }
    }
  },
  mounted() {
    this.getUser();
  },
  components: {
    info,
    password
  }
};
</script>
