<template>
  <v-card flat class="mt-5">
    <div class="pa-3">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="8" md="6">
            <h2 class="font-weight-light mb-4">Olá, você deseja alterar sua senha?</h2>
            <p>
              Para alterar a sua senha é muito fácil.<br />
              Basta clicar no botão abaixo <b>"Enviar Link"</b> e você receberá uma mensagem no WhatsApp com um link.
            </p>
            <p>Ao acessar o link, você deverá inserir uma nova senha e confirmar a mesma. <b>Pronto!</b></p>

            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-center">
                <v-icon large color="warning">mdi-alert-outline</v-icon>
                <div class="ms-3 align-center">
                  <p class="font-weight-medium mb-0">
                    O link expira em um período de <b>10min</b>.<br />
                    Caso expire, realize novamente o procedimento acima.
                  </p>
                </div>
              </div>
            </v-alert>

            <v-btn :loading="loading" :disabled="loading || block" @click="resetPassword" color="primary" class="mt-6">
              Enviar Link
            </v-btn>
          </v-col>

          <v-col cols="12" sm="4" md="6" class="d-none d-sm-flex justify-center position-relative">
            <v-img
              contain
              max-width="380"
              class="security-character"
              :src="require(`@/assets/images/user-profile/password-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import auth from "@/services/auth";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    loading: false,
    block: false
  }),
  methods: {
    resetPassword() {
      this.loading = true;
      auth.resetPassword
        .requestCode(this.user.email)
        .then(() => {
          this.$toast.fire({
            title: "Link para redefinição de senha enviado para seu e-mail.",
            icon: "success"
          });
          this.block = true;
        })
        .catch(err => {
          this.$toast.fire({
            title: err.message,
            icon: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters("account", ["user"])
  }
};
</script>

<style lang="scss" scoped>
.security-character {
  position: absolute;
  bottom: -20px;
}
</style>
